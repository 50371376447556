import {Injectable} from '@angular/core';
import {User, UserModel} from '../models/user';
import {RequesterService} from './requester.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import { StreamClientService } from './stream-client.service';
import { IdleUserDetectorService } from './idle-user-detector.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authState: Subject<boolean> = new Subject<boolean>();
  localStorageTokenFieldName = 'bearerToken';
  localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
  localStorageUserFieldName = 'userDetails';
  bearerToken = null;
  bearerTokenExpiry = null;
  userDetails: User | null = null;
  idleSubscriptionEstablished = false;

  constructor(
    private requesterService: RequesterService,
    public streamClient: StreamClientService,
    private idleUserDetectorService: IdleUserDetectorService,
    private router: Router,
  ) {
    this.getDataFromLocalStorage();
    // console.log(this.bearerToken, this.bearerTokenExpiry, this.userDetails)
    if (AuthService.masterIsLoggedIn) {
      this.streamClient.connect();
      this.authState.next(true);
    } else {
      this.authState.next(false);
    }
  }

  public static get masterIsLoggedIn(): boolean {
    let bearerToken = null;
    let bearerTokenExpiry = null;
    let userDetails: User | null = null;
    if (localStorage.getItem('bearerToken')) {
      bearerToken = localStorage.getItem('bearerToken');
      if (localStorage.getItem('bearerTokenExpiry')) {
        bearerTokenExpiry = localStorage.getItem('bearerTokenExpiry');
      }
    }
    if (localStorage.getItem('userDetails')) {
      userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    // console.log(bearerToken, bearerTokenExpiry > (new Date()).getTime(),  !!userDetails);
    if (!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails)) {
      AuthService.clearDataFromLocalStorage();
    }
    const app = localStorage.getItem('app');
    if (app !== 'owner') {
      AuthService.clearDataFromLocalStorage();
      return false;
    }
    return !!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails);
  }

  public get isLoggedIn(): boolean {
    return !!(this.bearerToken && this.bearerTokenExpiry > (new Date()).getTime() && !!this.userDetails);
  }

  public static getUserDetails(): User | null {
    if (localStorage.getItem('userDetails')) {
      return new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    return null;
  }

  public static isSuperAdmin(): boolean {
    if (localStorage.getItem('superAdmin')) {
      return JSON.parse(localStorage.getItem('superAdmin'));
    }
    return false;
  }

  public static isAdmin(): boolean {
    if (localStorage.getItem('admin')) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    return false;
  }

  public static clearDataFromLocalStorage() {
    const localStorageTokenFieldName = 'bearerToken';
    const localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
    const localStorageUserFieldName = 'userDetails';
    localStorage.removeItem(localStorageTokenFieldName);
    localStorage.removeItem(localStorageTokenExpiryFieldName);
    localStorage.removeItem(localStorageUserFieldName);
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('admin');
    localStorage.removeItem('app');
    localStorage.clear();
  }

  getDataFromLocalStorage() {
    if (localStorage.getItem(this.localStorageTokenFieldName)) {
      this.bearerToken = localStorage.getItem(this.localStorageTokenFieldName);
      if (localStorage.getItem(this.localStorageTokenExpiryFieldName)) {
        this.bearerTokenExpiry = localStorage.getItem(this.localStorageTokenExpiryFieldName);
      }
    }
    if (localStorage.getItem(this.localStorageUserFieldName)) {
      this.userDetails = new User(JSON.parse(localStorage.getItem(this.localStorageUserFieldName)));
    }
  }

  setDataToLocalStorage(userDetails: any, bearerToken: string, bearerTokenExpiry: string) {
    localStorage.setItem(this.localStorageTokenFieldName, bearerToken);
    localStorage.setItem(this.localStorageTokenExpiryFieldName, (new Date(bearerTokenExpiry)).getTime().toString());
    localStorage.setItem('superAdmin', String(userDetails.superAdmin));
    localStorage.setItem('admin', String(userDetails.superAdmin || !!userDetails.admin));
    this.setUserDataInLocalStorage(userDetails);
    localStorage.setItem('app', 'owner');
  }

  setUserDataInLocalStorage(userDetails: User) {
    localStorage.setItem(this.localStorageUserFieldName, JSON.stringify(new User(userDetails)));
  }

  public login(email: string, password: string): Observable<any> {
    this.startIdleTimer();
    return this.requesterService.request('post', 'auth/login', {email, password}, false)
      .pipe(
        map((apiResponse: any) => {
          console.log(apiResponse);
          if (apiResponse.data && apiResponse.data.user && apiResponse.data.user.role !== 'owner') {
            throw new Error('Invalid Email/Password');
          }
          if (apiResponse.data ) {
            console.log(apiResponse.data);
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
            this.streamClient.connect();
          }
          return apiResponse;
        })
      );
  }




  public loginViaToken(token: string): Observable<any> {
    this.startIdleTimer();
    localStorage.clear();
    return this.requesterService.request('post', 'auth/login-via-token', {token}, false)
    .pipe(
      map((apiResponse: any) => {
        if (apiResponse.data) {
          console.log(apiResponse.data);
          this.setDataToLocalStorage(apiResponse.data.user,apiResponse.data.token, apiResponse.data.expireAt);
          localStorage.setItem('quickJobToken', token);
          this.authState.next(true);
          this.streamClient.connect();
        }
        return apiResponse;
      })
    );
}


  public verifyRegistration(token: string): Observable<any> {
    AuthService.clearDataFromLocalStorage();
    return this.requesterService.request('post', 'auth/verify-email', {token}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data.user,apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }

  // public logout() {
  //   // API CAll
  //   AuthService.clearDataFromLocalStorage();
  //   this.authState.next(false);
  // }

  public logout(): Promise<any> {
    this.idleSubscriptionEstablished = false;
    this.idleUserDetectorService.reset();
    return new Promise<any>((resolve, reject) => {
      this.requesterService.request('get', 'auth/logout').subscribe(loggedOut => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      }, error => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      });
    });
  }

  public startIdleTimer() {
    if(!this.idleSubscriptionEstablished) {
      this.idleUserDetectorService.idleTimeOut
      .pipe(
        first(isIdle => isIdle === true)
      )
      .subscribe(async (isIdle) => {
        if (isIdle) {
          await this.logout();
          this.router.navigateByUrl("/auth/login");
        }
      });
      this.idleSubscriptionEstablished = true;
    }

  }


}
