import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzUploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { NzMessageService} from 'ng-zorro-antd/message';

import { Observable, Observer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConfigurationService } from '../../services/configuration.service';
import { RequesterService } from '../../services/requester.service';

@Component({
  selector: 'app-upload-box',
  templateUrl: './upload-box.component.html',
  styleUrls: ['./upload-box.component.scss']
})
export class UploadBoxComponent implements OnInit {
  @Input() attachments: any[] = [];
  @Output() attachmentsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() deleteEnabled: boolean = false;
  configuration = ConfigurationService;
  mediaUploadPath = this.configuration.apiBaseUrl + '/api/media/multiple';
  fileList: any[] = [];
  uploading: boolean = false;
  fileListForDisplay: any[] = [];
  mediaUploadURL = environment.apiUrl + '/api/media';
  attachmentsLoading = false;
  customHeaders: any = {
    Authorization: 'Bearer ' + localStorage.getItem('bearerToken')
  };
  existingFiles: any[] = [];
  @Input() itemsPerLine: number = 3;
  constructor(
    private msg: NzMessageService
  ) {}

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {
        // Don't allow executable files like exe, bat, etc.
        if (fileList.some(file => file.type === 'application/x-msdownload')) {
          this.msg.error(`Executable files are not allowed`);
          return fileList.filter(file => file.type !== 'application/x-msdownload');
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: NzUploadFile[]) => {
        return new Observable((observer: Observer<NzUploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];

  // tslint:disable-next-line:no-any
  handleChange(info: any): void {
    const fileList = info.fileList;
    this.fileList = [];
    // 2. read from response and show file link
    if (info.file.response) {
      info.file.url = info.file.response.url;
    }
    // 3. filter successfully uploaded files according to response from server
    // tslint:disable-next-line:no-any
    console.log([ ...fileList])
    this.fileList = fileList.filter((item: any) => {
      if (item.response) {
        return item.response.success;
      }
      return true;
    });
    this.attachmentsLoading = fileList.some( (item: any) => !item.response && item.status !== 'error'  );
    if(!this.attachmentsLoading) {
      this.attachments = [ ...this.existingFiles, ...this.fileList.filter(x => x.response ).map(x => x.response.data)];
      this.attachmentsChange.emit(this.attachments);
    }
  }

  removeFile(index: number): void {
    this.attachments.splice(index, 1);
    this.fileList.splice(index, 1);
    this.attachmentsChange.emit(this.attachments);
  }

  ngOnInit() {
    this.existingFiles = [ ...this.attachments];
  }


}
