// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  overflow: hidden;
}
.fileItem img {
  opacity: 1;
  display: block;
  height: auto;
  width: auto !important;
  transition: 0.5s ease;
  backface-visibility: hidden;
  max-width: 100%;
  object-fit: cover;
}
.fileItem .overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.fileItem:hover img {
  opacity: 0.3;
}

.fileItem:hover .overlay {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/common/shared-module/file-preview/file-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AAAE;EACE,UAAA;EACA,cAAA;EACA,YAAA;EACA,sBAAA;EACA,qBAAA;EACA,2BAAA;EACA,eAAA;EAEA,iBAAA;AACJ;AACE;EACE,qBAAA;EACA,UAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,oCAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,UAAA;AAAF","sourcesContent":[".fileItem {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 90px;\n  overflow: hidden;\n  img {\n    opacity: 1;\n    display: block;\n    height: auto;\n    width: auto !important;\n    transition: 0.5s ease;\n    backface-visibility: hidden;\n    max-width: 100%;\n    // height: 200px;\n    object-fit: cover;\n  }\n  .overlay {\n    transition: 0.5s ease;\n    opacity: 0;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    text-align: center;\n    width: 100%;\n  }\n}\n\n.fileItem:hover img {\n  opacity: 0.3;\n}\n\n.fileItem:hover .overlay {\n  opacity: 1;\n}\n\n@media (max-width: 767px) {\n  .fileItem {\n    img {\n      // height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
