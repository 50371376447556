import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AuthService } from "../../../common/services/auth.service";
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { User } from "../../../common/models/user";
import { MenuService } from "../../../common/services/menu.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "src/app/auth/change-password/change-password.component";
import { ChatDispatcherService } from "../../../main/chat/services/chat-dispatcher.service";
import { ChannelData } from "stream-chat";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { StreamClientService } from "src/app/common/services/stream-client.service";
import * as moment from "moment";
import { CommunicationService } from "src/app/common/services/communication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>("");
  inputTextContent: string;

  userDetails: User;
  menuData: any[] = [];
  currentUrl: any | null = null;
  isCollapsed = false;
  showDashboard = false;
  visibleDrawer = false;
  @Output() menuCollapsed?: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuMouseOver?: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuMouseLeave?: EventEmitter<any> = new EventEmitter<any>();
  unreadCount = 0;
  channelList: ChannelData[] = [];
  @ViewChild("inpSearch", { static: false }) inpSearch: ElementRef<any>;
  showPopover = false;
  notifications: any[];
  subscriptions: Subscription[] = [];
  isSearchClicked: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenuService,
    private modalService: NzModalService,
    private chatDispatcherService: ChatDispatcherService,
    private notificationService: NzNotificationService,
    private streamClientService: StreamClientService,
    public communicationService: CommunicationService
  ) {
    // @ts-ignore
    this.clearSearch();
  }

  ngOnInit() {
    this.userDetails = AuthService.getUserDetails();
    this.currentUrl = this.router.url;
    this.showDashboard = this.router.url.includes("/auth/post-register");
    this.chatDispatcherService.notificationCounter.subscribe(
      (subscribedData) => {
        this.unreadCount = subscribedData.count;
        this.channelList = subscribedData.channelList;
      }
    );
    setTimeout(() => {
      if (this.unreadCount > 0) {
        this.notificationService.info("Reminder", "You have unread messages");
      }
    }, 5000);
    // @ts-ignore
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.currentUrl = event.url;
        this.showDashboard = this.currentUrl.includes("/auth/post-register");
      }
    });

    this.authService.authState.subscribe((loggedIn) => {
      console.log("SUB LOggedIn", loggedIn);
      if (loggedIn) {
        this.userDetails = AuthService.getUserDetails();
      } else {
        this.userDetails = null;
      }
    });

    this.subscriptions.push(
      this.streamClientService.$allNotifications.subscribe((data) => {
        this.notifications = [];
        data.notificationsByGroup.forEach((group) => {
          const allActivities = group.activities.map((x) => ({
            ...x,
            is_read: group.is_read,
            is_seen: group.is_seen,
          }));
          this.notifications.push(...allActivities);
        });
      })
    );

    this.subscriptions.push(
      this.streamClientService.getRealtimeNotification().subscribe((data) => {
        this.notificationService.info(
          data.object,
          moment(data.time).format("LLL")
        );
      })
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.map((x) => x.unsubscribe());
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === "/panel/dashboard") {
          // Dashboard from any Other Page
          // console.log("In Dashboard");
          const searchKeyword = localStorage.getItem("searchKeyword");
          if (searchKeyword) {
            this.searchText.next(searchKeyword);
            this.showPopover = true;
          }
        }
      }
    });
  }

  openChat(cid) {
    this.chatDispatcherService.openChat.next(cid);
  }
  menuOpenCloseChange() {
    this.menuCollapsed.emit(this.isCollapsed);
  }

  async logout() {
    await this.authService.logout();
    this.router.navigateByUrl("/auth/login");
  }

  profile() {
    this.router.navigateByUrl("/panel/user/profile");
  }

  goToLink(url: string) {
    console.log(url);
    this.router.navigateByUrl(url);
  }

  openCloseDrawer() {
    this.visibleDrawer = !this.visibleDrawer;
  }

  changePassword() {
    const modal = this.modalService.create({
      nzTitle: "Change Password",
      nzContent: ChangePasswordComponent,
      nzWidth: "975px",
      nzStyle: { top: "20px" },
      nzMaskClosable: false,
      nzData: {},
      nzFooter: null,
    });
    modal.afterOpen.subscribe(() => console.log("[afterOpen] emitted!"));
    // Return a result when closed
    modal.afterClose.subscribe((result) => {});
  }

  onLogoClick() {
    if (
      this.currentUrl.includes("/auth/login") ||
      this.currentUrl.includes("/auth/register")
    ) {
      window.location.href = "https://attender.ai";
    } else {
      this.router.navigateByUrl("/panel");
    }
  }

  onSearch(text: any) {
    this.searchText.next(text.target.value);
  }

  get searchTextObservable() {
    return this.searchText as Observable<string>;
  }
  closeSearch() {
    this.showPopover = false;
  }

  clearSearch() {
    this.inputTextContent = "";
    this.searchText.next("");
    localStorage.removeItem("searchParams");
    localStorage.removeItem("searchKeyword");
    this.closeSearch();
  }

  openSearch(): void {
    this.isSearchClicked = !this.isSearchClicked;
  }
}
