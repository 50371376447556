import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-pdf-preview-render',
  templateUrl: './pdf-preview-render.component.html',
  styleUrls: ['./pdf-preview-render.component.scss']
})
export class PdfPreviewRenderComponent implements OnInit {
  // @Input() filePath : any;
  filePathUrl: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer,
    @Inject(NZ_MODAL_DATA) private filePath: any) { }

  ngOnInit(): void {
    this.filePathUrl = this._sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + '/uploads/'+ this.filePath)
  }

}
