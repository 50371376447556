// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picture-card-box ::ng-deep .ant-card-body {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  position: absolute;
  top: 2px;
  left: 2px;
}

.item-box {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/common/shared-module/media-box/media-box.component.scss"],"names":[],"mappings":"AAGI;EACE,aAAA;EACA,aAAA;EACA,eAAA;AAFN;;AAOA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;AAJF;;AAMA;EACE,kBAAA;AAHF","sourcesContent":[".picture-card-box {\n  // max-height: 315px;\n  ::ng-deep {\n    .ant-card-body {\n      padding: 24px;\n      display: flex;\n      flex-wrap: wrap;\n    }\n  }\n}\n\n.checkbox {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n}\n.item-box {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
