import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  url: string;
  changePasswordVisible: boolean = false;
  constructor(
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
    private modalService: NzModalService,
  ) {
    /* this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        const mainUrl = this.url.split("?")[0];
        if (mainUrl !== "/auth/verify-registration") {
          this.router.navigate(["auth/quick-login"], {
            queryParams: { ...params, redirectTo: mainUrl },
          });
        }
      }
    });
    router.events.pipe(first()).subscribe((val: any) => {
      const { url } = val;
      this.url = url;
    }); */
  }
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('params FROM APP COMPONENT',params, this.router.url);
      if(params.changePassword) {
        if(Number(params.changePassword) === 1) {
          this.changePasswordVisible = true;
          this.openChangePasswordModal();
        }
      }
    });
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.create({
      nzTitle: 'Set Password',
      nzContent: ChangePasswordComponent,
      nzData: {
        init: true
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
    });

    modalRef.afterClose.subscribe(result => {
      this.changePasswordVisible = false;
      this.router.navigate([], { queryParams: { changePassword: null }, queryParamsHandling: 'merge' });
    });
  }

  ngOnDestroy() {
     this._idleUserDetectorService.stop();
  }
}
