// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nz-card {
  width: 1000px;
}
nz-card ::ng-deep .ant-card-body {
  padding: 0;
}

nz-result {
  width: 1000px;
}

nz-page-header {
  width: 1000px;
}

.result-container {
  min-height: 40vh;
  max-height: 80vh;
  overflow-y: auto;
}

nz-select {
  width: 100%;
}

::ng-deep .ant-table-thead > tr > th,
::ng-deep .ant-table-tbody > tr > td,
::ng-deep .ant-table tfoot > tr > th,
::ng-deep .ant-table tfoot > tr > td {
  padding: 16px 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/no-sidebar/header/global-search/global-search.component.scss"],"names":[],"mappings":"AACA;EACE,aAFM;AAER;AAEI;EACE,UAAA;AAAN;;AAIA;EACE,aAVM;AASR;;AAIA;EACE,aAdM;AAaR;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIE;;;;EAIC,iBAAA;AADH","sourcesContent":["$width: 1000px;\nnz-card {\n  width: $width;\n  ::ng-deep {\n    .ant-card-body {\n      padding: 0;\n    }\n  }\n}\nnz-result {\n  width: $width;\n}\n\nnz-page-header {\n  width: $width;\n}\n\n.result-container {\n  min-height: 40vh;\n  max-height: 80vh;\n  overflow-y: auto;\n}\n\nnz-select {\n  width: 100%;\n}\n::ng-deep{\n  .ant-table-thead > tr > th,\n.ant-table-tbody > tr > td,\n.ant-table tfoot > tr > th,\n.ant-table tfoot > tr > td {\n   padding: 16px 4px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
